import React from 'react'
import Menu from './menu';


function home() {


    const coverStyles = {
        cover: {
            backgroundImage: `url('images/img web zahmoul/RIZ-THAI-CREVETTES-356x391.png')`, // Remplacez le chemin par le bon
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
        }


    };
    return (
        <div id="top">
            <main>
                <nav className="navbar navbar-expand-lg">
                    <div className="container"  style={{
            display: 'flex',
            flexWrap: 'nane',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}
>
                        <a className="navbar-brand" >
                            <img src="images/LOGO.png" className='logo' style={{ height: "50px" }} />
                            <span>Orienthaï </span>
                        </a>

                        <div className="d-lg-none ms-auto me-4">
                            <button className="btn " style={{ background: '#EC6622 ', color: '#fff' }} onClick={() => window.location.href = 'https://www.mymakseb.com/ordering/restaurant/menu?restaurant_uid=13bfcb47-e69a-485f-b790-b5dab1fbd2ea&dine_in=true'}>Commander</button>
                        </div>



                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-lg-5 me-lg-auto">

                            </ul>

                            <div className="d-none d-lg-block">
                                <button className="btn " style={{ background: '#EC6622 ', color: '#fff' }} onClick={() => window.location.href = 'https://www.mymakseb.com/ordering/restaurant/menu?restaurant_uid=13bfcb47-e69a-485f-b790-b5dab1fbd2ea&dine_in=true'}>Commander</button>
                            </div>
                        </div>
                    </div>
                </nav>


                <section className="hero-section d-flex justify-content-center align-items-center" id="section_1">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-12 mx-auto">
                                <h1 className="text-white text-center">Bienvenue chez Orienthaï</h1>


                            </div>

                        </div>
                    </div>
                </section>


                <section className="featured-section">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-4 col-12 mb-4 mb-lg-0" >
                                <div className="custom-block" style={{ width: '350px', height: '486px', overflow: 'hidden', }}>
                                    <video className="custom-block-video" autoPlay loop muted playsInline style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                                        <source src="images/img web zahmoul/VID-20240516-WA0009.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>




                            <div className="col-lg-6 col-12">
                                <div className="custom-block custom-block-overlay">
                                    <div className="d-flex flex-column h-100">


                                        <img src="images/img web zahmoul/IMG-DROITE-SECTION1.png" style={{ height: "488px", width: "636px" }} className="custom-block-image img-fluid" alt="" />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="explore-section section-padding" id="section_2">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="mb-4"></h2>
                            </div>
                        </div>
                    </div>



                    <Menu></Menu>
                </section>


                <section className="timeline-section section-padding" id="section_3">
                    <div className=""></div>


                </section>

                <section className="faq-section " id="section_4">
                    <div className="container">

                    </div>
                </section>
                <section className="contact-section section-padding section-bg" id="section_5">
                    <div className="container">
                        <iframe
                            className="google-map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2898.1297089293986!2d-0.5631764854927543!3d44.7761557790987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5527d3a92a6941%3A0x5e99233cde11c10f!2s517%20Route%20de%20Toulouse%2C%2033140%20Villenave-d'Ornon%2C%20France!5e0!3m2!1sen!2sus!4v1620669624504!5m2!1sen!2sus"
                            width="100%"
                            height="250"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Map of 517 RTE DE TOULOUSE, 33140 VILLENAVE D'ORNON" // Add a title attribute
                            aria-label="Map of 517 RTE DE TOULOUSE, 33140 VILLENAVE D'ORNON" // Add an aria-label attribute for accessibility
                        ></iframe>
                    </div>
                </section>

            </main>
            <footer className="site-footer section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-12 mb-4 pb-2">
                            <a className="navbar-brand" >
                                <img src="images/LOGO.png" style={{ height: "50px", '@media (max-width: 576px)': { height: "25px" } }} />
                                <span>Orienthai </span>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-4 col-10">
                            <h6 className="site-footer-title mb-3">Information</h6>

                            <p className="text-white d-flex mb-1">
                                <a href="tel: 305-240-9671" className="site-footer-link">
                                    07 54 12 10 73
                                </a>
                            </p>

                            <table style={{ border: "0" }}>
                                <tbody>
                                    <h6 className="site-footer-title mb-3">Horaires d'ouvertures</h6>

                                    <tr>
                                        <td className="text-white">
                                            <a href="mailto:info@company.com" className="site-footer-link">
                                                Lundi - Jeudi, Dimanche 11:30 - 23:00
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-white">
                                            <a href="mailto:info@company.com" className="site-footer-link">
                                                Vendredi 14:00 - 00:00
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-white">
                                            <a href="mailto:info@company.com" className="site-footer-link">
                                                Samedi 11:30 - 00:00
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div className="col-lg-3 col-md-4 col-6 mb-4 mb-lg-0">

                        </div>

                        <div className="col-lg-19   ">
                            {/* <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            English</button>

                            <ul className="dropdown-menu">
                                <li><button className="dropdown-item" type="button">Thai</button></li>

                                <li><button className="dropdown-item" type="button">Myanmar</button></li>

                                <li><button className="dropdown-item" type="button">Arabic</button></li>
                            </ul>
                        </div> */}

                            <p className="copyright-text mt-lg-5 mt-4">
                                <span><i className="fas fa-copyright me-2"></i><strong>Powered by Makseb Solutions</strong></span>
                            </p>


                        </div>

                    </div>
                </div>
            </footer>
        </div >
    )
}

export default home
