import React from 'react';
import HorizontalScrollMenu from 'react-horizontal-scroll-menu';

const Menu = () => {
  const menuItems = [
    { name: 'Item 1', image: 'images/img web zahmoul/IMG-20240516-WA0011.jpg' },
    { name: 'Item 2', image: 'images/img web zahmoul/PAD-THAI-BOEUF-356x391.8-px.png' },
    { name: 'Item 3', image: 'images/img web zahmoul/IMG-20240516-WA0008.jpg' },
    { name: 'Item 4', image: 'images/img web zahmoul/TEMPURA-CREVETTES-356x391.8-px.png' },
    { name: 'Item 5', image: 'images/img web zahmoul/IMG-20240516-WA0011.jpg' },
    { name: 'Item 6', image: 'images/img web zahmoul/RIZ-THAI-CREVETTES-356x391.png' },
    // Add more items as needed
  ];

  const MenuItem = ({ item }) => (
    <div className="col-lg-12 col-md-6 col-12 mb-4 mb-lg-0">
      <div className="">
        <a href="https://www.mymakseb.com/ordering/restaurant/menu?restaurant_uid=13bfcb47-e69a-485f-b790-b5dab1fbd2ea&dine_in=true">
          <img src={item.image} style={{ height: "392px", width: '415px' }} alt={item.name} />
        </a>
      </div>
    </div>
  );

  const menu = menuItems.map((item, index) => <MenuItem item={item} key={index} />);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="design-tab-pane" role="tabpanel" aria-labelledby="design-tab" tabIndex="0">
              <div className="row">
                <HorizontalScrollMenu
                  data={menu}
        
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
